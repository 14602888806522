import { compile } from "vue";
import { RouterView } from "vue-router";

const moduleRoute = {
  path: "/revenue",
  name: "revenue",
  meta: {
    module: "revenue",
    title: 'Razayya Financial | Insights | Revenue',
    requiresAuth: false
  },
  redirect: '/revenue/entries',
  component: () => import(/* webpackChunkName: "journalEntry" */ "./revenueModule.vue"),
  children: [
    {
      path:'stores',
      name:"revenue.stores",
      component: () => import ('./views/StoresListView.vue'),
      meta: {
        breadcrumb:
        {
          label:'Stores',
          parent:'revenue.home'
        },
        icon: 'mdi-store',
        showInNav: true
      }
    },
    {
      path:'accounts',
      name:"revenue.accounts",
      component: () => import ('./views/AccountsListView.vue'),
      meta: {
        breadcrumb:
        {
          label:'Accounts',
          parent:'revenue.home'
        },
        icon: 'mdi-checkbook',
        showInNav: true
      }
    },
    {
      path:'entries',
      name:'revenue.entries',
      component: RouterView,
      children: [
        {
          path:"/revenue/entires/home",
          name:"revenue.entries.home",
          component: () => import('./views/RevenueEntriesView.vue'),
          meta: {
            breadcrumb: 
            {
              label: 'Entries',
              parent: 'revenue.home'
            },
            icon: 'mdi-notebook',
            showInNav: true
          }
        },
        {
          path:"/revenue/entires/unbalanced",
          name:"revenue.entires.unbalanced",
          component: () => import('./views/UnbalancedTransactionJournalEntriesView.vue'),
          meta: {
            breadcrumb:{
              label:'Unbalanced',
              parent:'revenue.entries'
            },
            icon: 'mdi-scale-unbalanced',
            showInNav: true
          }
        },
      ],
      meta: {
        breadcrumb: 
            {
              label: 'Revenue Entries',
              parent: 'revenue.home'
            },
            icon: 'mdi-notebook',
            showInNav: true
      }
    },
    {
      path:'transactions',
      name:'revenue.transactions',
      component: () => import('./views/TransactionsListView.vue'),
      meta: {
        breadcrumb:{
          label: 'Transactions',
          parent: 'revenue.home'
        },
        icon: 'mdi-cash',
        showInNav: true
      }
    },
    // {
    //   path:'transaction/batches/:id',
    //   name:'revenue.transaction.batches.detail',
    //   component: () => import('./views/TransactionReconcileBatchDetailView.vue'),
    //   meta: {
    //     breadcrumb:{
    //       label: 'Transaction Batch Details',
    //       parent: 'revenue.transaction.batches'
    //     },
    //     icon: 'mdi-cash'
    //   }
    // },
    {
      path:'deposits',
      name:'revenue.deposits',
      component: () => import('./views/DepositsListView.vue'),
      meta: {
        breadcrumb:{
          label: 'Deposits',
          parent: 'revenue.home'
        },
        icon: 'mdi-bank',
        showInNav: true
      }
    },
    {
      path:"import-file-tempaltes",
      name:"revenue.importFileTempaltes",
      component: () => import('./views/ImportFileTemplatesView.vue'),
      meta: {
        breadcrumb: 
        {
          label: 'Import File Templates',
          parent: 'revenue.home'
        },
        icon: 'mdi-file'
      }
    },
    {
      path:"import-configuration",
      name:"revenue.importConfiguration",
      component: () => import('./views/TransactionToJournalEntryMappingsView.vue'),
      meta: {
        breadcrumb: 
        {
          label: 'Configuration',
          parent: 'revenue.home'
        },
        icon: 'mdi-cash'
      }
    },
    {
      path:'stores/:id',
      name:"revenue.stores.detail",
      component: () => import ('./views/StoreDetailView.vue'),
      meta: {
        breadcrumb:
        {
          label:'Store Detail',
          parent:'revenue.stores'
        },
        icon: 'mdi-store'
      }
    }
  ]
};

export default router => {
  router.addRoute(moduleRoute);
  return moduleRoute;
}